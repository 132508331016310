import { Component, OnInit, EventEmitter, Output } from '@angular/core';

@Component({
  selector: 'iosinstallation-popover',
  templateUrl: './iosinstallation-popover.component.html',
  styleUrls: ['./iosinstallation-popover.component.scss']
})
export class IOSInstallationPopoverComponent implements OnInit {
  
  @Output() close = new EventEmitter<boolean>();

  constructor() { }

  ngOnInit() {
  }
  
  public closePopover(){
    this.close.emit(false);
  }
}
