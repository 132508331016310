import { Subject, Observable } from "rxjs";
import { Injectable } from "@angular/core";

@Injectable()
export class StepperButtonsService {
    private subject = new Subject<any>();
 
    sendActiveStep(activeStep: number) {
        this.subject.next({activeStep : activeStep});
    }
 
    clearActiveStep() {
        this.subject.next();
    }
 
    getActiveStep(): Observable<any> {
        return this.subject.asObservable();
    }
}
