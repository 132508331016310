import { ValidatorFn, FormControl, FormGroup } from "../../node_modules/@angular/forms";

const emailRE = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
const cellphoneRE = /^(?:(?:00)?549?)?0?(?:11|[2368]\d)(?:(?=\d{0,2}15)\d{2})??\d{8}$/;
const idRE = /^\d{8}(?:[-\s]\d{4})?$/;

export function noEmptyField(form: FormControl) {
    if(typeof form.value === "string"){
        let ctrlvalue = form.value || '';
        ctrlvalue = ctrlvalue.trim();
        return ctrlvalue.length === 0 && form.value && form.value.length ? { noEmptyField: false } : null;    
    } else {
        return null;   
    }
}

export function validPhoneOrEmail(form: FormGroup){
    let errorCounter: number = 0;
    if(form.get('phone')){
        if(cellphoneRE.test(String(form.get('phone').value).toLowerCase())){
            form.get('phone').setErrors(null);
        }else{
            form.get('phone').setErrors({validPhoneOrEmail: false});
            errorCounter++;
        }
    }
    if(form.get('email')){
        if(emailRE.test(String(form.get('email').value).toLowerCase())){
            form.get('email').setErrors(null);
        }else{
            form.get('email').setErrors({validPhoneOrEmail: false});
            errorCounter++;
        }
    }
    if(errorCounter > 1){
        return {validPhoneOrEmail: {valid: false}};
    }
    if(form.get('phone') && form.get('email')){
        form.get('phone').setErrors(null);
        form.get('email').setErrors(null);
    }
    return null;
    
}
  
export function validId(form: FormControl){
    if(idRE.test(String(form.value).toLowerCase())){
        return null;
    }
    return {validId: true};
}

export function getPhoneFormatted(phone: string): any{
    const cellphoneRE = /^(?:(?:00)?549?)?0?(?:11|[2368]\d)(?:(?=\d{0,2}15)\d{2})??\d{8}$/;
    if(cellphoneRE.test(phone)){
      return phone;
    }
    return null;
}

export function getEmailFormatted(email: string): any{
    const emailRE = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    if(emailRE.test(email)){
      return email;
    }
    return null;
}
  