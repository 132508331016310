import { Component, OnInit, Input, ElementRef, ViewChild } from "@angular/core";
import { FormArray } from "@angular/forms";
declare var $: any;

@Component({
  selector: "main-stepper",
  templateUrl: "./main-stepper.component.html",
  styleUrls: ["./main-stepper.component.scss"]
})
export class MainStepperComponent implements OnInit {
  @Input() sourceArrayForm: FormArray;

  public activeStep: number;

  constructor() {}

  ngOnInit() {
    this.activeStep = 0;
  }

  public selectStep(step: any) {
    this.activeStep = step;
    $(".carousel.vert").carousel(step);
  }
}
