import { Component, OnInit, OnDestroy } from '@angular/core';
import { OpenMenuService } from '../main-menu/open-menu.service';
import { ISubscription } from 'rxjs/Subscription';

@Component({
  selector: 'page-header',
  templateUrl: './page-header.component.html',
  styleUrls: ['./page-header.component.scss']
})
export class PageHeaderComponent implements OnInit, OnDestroy {

  public blurred: boolean;
  private subs: ISubscription[] = [];

  constructor(private _openMenuService: OpenMenuService) {
    this.blurred = false;
  }

  ngOnInit() {
    const self = this;
    self.subs.push( 
      self._openMenuService.getBlurredBackground().subscribe(blurred=>{
        self.blurred = blurred;
      })
    );
  }

  ngOnDestroy() {
    this.subs.forEach(sub => sub.unsubscribe());
  }
}
