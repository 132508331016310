import { Injectable } from "@angular/core";
import { Observable, of } from "rxjs";
import { LocalStorage } from "@ngx-pwa/local-storage";

@Injectable()
export class LocalStorageService {
  constructor(protected localStorage: LocalStorage) {}

  public getAgency(): Observable<any> {
    return this.localStorage.getItem("agency");
  }
  public setAgency(name: string): Observable<any> {
    return this.localStorage.setItem("agency", name);
  }
  public clear(): Observable<any> {
    return this.localStorage.clear();
  }
}
