import { BrowserModule } from "@angular/platform-browser";
import { NgModule } from "@angular/core";
import { AppComponent } from "./app.component";
import { ServiceWorkerModule } from "@angular/service-worker";
import { environment } from "../environments/environment";
import { AppService } from "./app.service";
import { GlobalConstants } from "./app.constants";
import { ReactiveFormsModule } from "@angular/forms";
import { RoutingModule } from "./app.routing";
import { HttpClientModule } from "@angular/common/http";

//Shared Components
import { PageHeaderComponent } from "./shared/page-header/page-header.component";
import { MainMenuComponent } from "./shared/main-menu/main-menu.component";
import { SpinnerComponent } from "./shared/spinner/spinner.component";
import { MainStepperComponent } from "./shared/main-stepper/main-stepper.component";
import { StepperButtonsComponent } from "./shared/stepper-buttons/stepper-buttons.component";

//Views
import { SimulatorViewComponent } from "./views/simulator-view/simulator-view.component";
import { AboutUsViewComponent } from "./views/about-us-view/about-us-view.component";
import { TermConditionsViewComponent } from "./views/term-conditions-view/term-conditions-view.component";
import { BenefitsViewComponent } from "./views/benefits-view/benefits-view.component";
import { ContactUsViewComponent } from "./views/contact-us-view/contact-us-view.component";
import { FormalitiesViewComponent } from "./views/formalities-view/formalities-view.component";
import { SimulateYourCreditComponent } from "./views/simulator-view/simulate-your-credit/simulate-your-credit.component";
import { PersonalInformationComponent } from "./views/simulator-view/personal-information/personal-information.component";
import { FinalizeComponent } from "./views/simulator-view/finalize/finalize.component";

//Services
import { ContactUsViewService } from "./views/contact-us-view/contact-us-view.service";
import { SimulatorViewService } from "./views/simulator-view/simulator-view.service";
import { OpenMenuService } from "./shared/main-menu/open-menu.service";
import { StepperButtonsService } from "./shared/stepper-buttons/stepper-buttons.service";
import { IOSInstallationPopoverComponent } from "./shared/iosinstallation-popover/iosinstallation-popover.component";
import { LocalStorageService } from "./app.local-storage.service";

@NgModule({
  declarations: [
    AboutUsViewComponent,
    TermConditionsViewComponent,
    AppComponent,
    BenefitsViewComponent,
    ContactUsViewComponent,
    FormalitiesViewComponent,
    SimulatorViewComponent,
    PageHeaderComponent,
    MainMenuComponent,
    SpinnerComponent,
    MainStepperComponent,
    SimulateYourCreditComponent,
    PersonalInformationComponent,
    FinalizeComponent,
    StepperButtonsComponent,
    IOSInstallationPopoverComponent
  ],
  imports: [
    BrowserModule,
    RoutingModule,
    ServiceWorkerModule.register("ngsw-worker.js", {
      enabled: environment.production
    }),
    ReactiveFormsModule,
    HttpClientModule
  ],
  providers: [
    AppService,
    ContactUsViewService,
    SimulatorViewService,
    GlobalConstants,
    OpenMenuService,
    LocalStorageService,
    StepperButtonsService
  ],
  bootstrap: [AppComponent]
})
export class AppModule {}
