import { Component, OnInit, OnDestroy } from "@angular/core";
import { FormGroup, FormControl, Validators } from "@angular/forms";
import { noEmptyField, validPhoneOrEmail } from "../../validators";
import { ContactUsViewService } from "./contact-us-view.service";
import { ISubscription } from "rxjs/Subscription";
import { WhatsappContact } from "../../../models/whatsapp-contact.model";

import { LocalStorageService } from "../../app.local-storage.service";

@Component({
  selector: "contact-us-view",
  templateUrl: "./contact-us-view.component.html",
  styleUrls: ["./contact-us-view.component.scss"]
})
export class ContactUsViewComponent implements OnInit, OnDestroy {
  private subs: ISubscription[] = [];
  public contactForm: FormGroup;
  private whatsAppContact: WhatsappContact;
  public sending: boolean;
  public submitted: boolean;
  public href: string;

  constructor(
    private contactUsViewService: ContactUsViewService,
    private _localStorageService: LocalStorageService
  ) {
    this.sending = false;
    this.submitted = false;
  }

  ngOnInit() {
    this.contactForm = new FormGroup({
      firstName: new FormControl("", {
        validators: [Validators.required, noEmptyField]
      }),
      lastName: new FormControl("", {
        validators: [Validators.required, noEmptyField]
      }),
      message: new FormControl("", {
        validators: [Validators.required, noEmptyField]
      }),
      userContact: new FormGroup(
        {
          phone: new FormControl(""),
          email: new FormControl(""),
          agency: new FormControl("")
        },
        {
          validators: [validPhoneOrEmail]
        }
      )
    });
    this._localStorageService.getAgency().subscribe(
      value => {
        this.contactForm
          .get("userContact")
          .get("agency")
          .setValue(value);
      },
      err => {
        console.log(err);
      }
    );

    this.whatsAppContact = this.contactUsViewService.getWhatsAppContact();
    this.href = `https://api.whatsapp.com/send?phone=${
      this.whatsAppContact.phone
    }&text=${encodeURIComponent(this.whatsAppContact.message)}`;
  }

  onSubmit() {
    this.submitted = true;
    if (this.contactForm.valid) {
      this._localStorageService
        .setAgency(this.contactForm.get("userContact").get("agency").value)
        .subscribe();
      if (!this.sending) {
        this.sending = true;
        this.subs.push(
          this.contactUsViewService.saveContact(this.contactForm).subscribe(
            value => {
              this.sending = false;
              this.contactForm.reset();
            },
            err => {
              console.log(err);
              this.sending = false;
            }
          )
        );
      }
    }
  }

  public checkValid() {
    if (
      this.contactForm.get("firstName").valid &&
      this.contactForm.get("lastName").valid &&
      this.contactForm.get("message").valid &&
      (this.contactForm.get("email").valid ||
        this.contactForm.get("phone").valid)
    ) {
      return true;
    }
    return false;
  }

  ngOnDestroy() {
    this.subs.forEach(sub => sub.unsubscribe());
  }
}
