import { Injectable } from "@angular/core";
import { AppService } from "../../app.service";
import { Contact } from "../../../models/contact.model";
import { FormGroup } from "@angular/forms";
import { getEmailFormatted, getPhoneFormatted } from "../../validators";
import { HttpParams } from "@angular/common/http";
import { WhatsappContact } from "../../../models/whatsapp-contact.model";

@Injectable()
export class ContactUsViewService {
  private whastAppContact: WhatsappContact;

  constructor(private appService: AppService) {}

  public saveContact(form: FormGroup) {
    const obj: Contact = {
      firstName: form.get("firstName").value.trim(),
      lastName: form.get("lastName").value.trim(),
      email: String(form.get("userContact").get("email").value)
        .trim()
        .toLowerCase(),
      phone: String(form.get("userContact").get("phone").value)
        .trim()
        .toLowerCase(),
      agency: String(form.get("userContact").get("agency").value)
        .trim()
        .toLowerCase(),
      message: form.get("message").value.trim()
    };
    // const url = `${this.appService.constants.apiUrl.askContact}/${
    //   obj.firstName
    // }/${obj.lastName}/${obj.email}/${obj.phone}/${obj.message}/${obj.agency}`;
    return this.appService.httpPostData(
      this.appService.constants.apiUrl.askContact,
      obj
    );
  }

  public setWhatsAppContact(whastAppContact: WhatsappContact) {
    this.whastAppContact = whastAppContact;
  }

  public getWhatsAppContact(): WhatsappContact {
    return this.whastAppContact;
  }
}
