import { Component, OnInit } from "@angular/core";

@Component({
  selector: "term-conditions-view",
  templateUrl: "./term-conditions-view.component.html",
  styleUrls: ["./term-conditions-view.component.scss"]
})
export class TermConditionsViewComponent implements OnInit {
  constructor() {}

  ngOnInit() {}
}
