import {
  Component,
  OnInit,
  Input,
  OnChanges,
  OnDestroy,
  EventEmitter
} from "@angular/core";
import { FormGroup } from "@angular/forms";
import { Subject } from "rxjs/internal/Subject";
import { OpenMenuService } from "../../../shared/main-menu/open-menu.service";
import { ISubscription } from "rxjs/Subscription";
import { StepperButtonsService } from "../../../shared/stepper-buttons/stepper-buttons.service";
import { SimulatorViewService } from "../simulator-view.service";
import { distinctUntilChanged, debounceTime } from "rxjs/operators";

import { ContactUsViewService } from "../../contact-us-view/contact-us-view.service";
import { WhatsappContact } from "../../../../models/whatsapp-contact.model";

@Component({
  selector: "simulate-your-credit",
  templateUrl: "./simulate-your-credit.component.html",
  styleUrls: ["./simulate-your-credit.component.scss"]
})
export class SimulateYourCreditComponent implements OnInit, OnDestroy {
  @Input() sourceFormGroup: FormGroup;

  private subs: ISubscription[] = [];
  public money: string;
  public showPrefix: boolean;
  public feePriceH: string;
  public feePriceD: string;
  public gettingFeePrice: boolean;
  private subject = new Subject<string>();
  private getAverageFeeSubscription: ISubscription;
  public submitted: boolean;
  public placeholderValue: string;
  private whatsAppContact: WhatsappContact;
  public href: string;
  public firstTime: boolean = true;

  constructor(
    private _openMenuService: OpenMenuService,
    private _simulatorViewService: SimulatorViewService,
    private _stepperButtons: StepperButtonsService,
    private contactUsViewService: ContactUsViewService
  ) {
    this.money = "0.00";
    this.gettingFeePrice = false;
    this.feePriceD = "0.00";
    this.feePriceH = "0.00";
    this.submitted = false;
    this.showPrefix = false;
  }

  ngOnInit() {
    this.showPrefix = false;
    const self = this;
    if (self.sourceFormGroup.get("feePriceH").value !== null) {
      self.feePriceH = Number(
        this.sourceFormGroup.get("feePriceH").value
      ).toLocaleString("es-AR", {
        style: "currency",
        currency: "ARS",
        minimumFractionDigits: 2
      });
    }
    if (self.sourceFormGroup.get("feePriceD").value !== null) {
      self.feePriceD = Number(
        this.sourceFormGroup.get("feePriceD").value
      ).toLocaleString("es-AR", {
        style: "currency",
        currency: "ARS",
        minimumFractionDigits: 2
      });
    }

    const moneyQuantity =
      self.sourceFormGroup.get("loanValue").value !== null
        ? self.sourceFormGroup.get("loanValue").value
        : self.sourceFormGroup.get("creditValues").value[0];
    if (this.firstTime) {
      this.firstTime = false;
    } else {
      self.setMoneyValue(moneyQuantity);
    }
    this.subs.push(
      self.sourceFormGroup.valueChanges
        .pipe(debounceTime(150), distinctUntilChanged())
        .subscribe(val => {
          self.sourceFormGroup.updateValueAndValidity({ emitEvent: false });
          if (
            val &&
            val.carModel &&
            val.feesAmount &&
            self.inRangeValue(val.loanValue)
          ) {
            self.getAverageFee();
          } else {
            if (self.getAverageFeeSubscription) {
              self.getAverageFeeSubscription.unsubscribe();
            }
          }
        })
    );

    this.placeholderValue =
      "Monto del préstamo hasta " +
      Number(this.sourceFormGroup.get("creditValues").value[1]).toLocaleString(
        "es-AR",
        {
          style: "currency",
          currency: "ARS",
          minimumFractionDigits: 0
        }
      );
    this.whatsAppContact = this.contactUsViewService.getWhatsAppContact();
    this.href = `https://api.whatsapp.com/send?phone=${
      this.whatsAppContact.phone
    }&text=${encodeURIComponent(this.whatsAppContact.message)}`;
  }
  private inRangeValue(val) {
    let comparator = Number(String(val).replace(/\./g, ""));
    return (
      comparator &&
      comparator <= this.sourceFormGroup.get("creditValues").value[1] &&
      comparator >= this.sourceFormGroup.get("creditValues").value[0]
    );
  }

  private getAverageFee() {
    if (this.getAverageFeeSubscription) {
      this.getAverageFeeSubscription.unsubscribe();
    }
    this.gettingFeePrice = true;
    this.getAverageFeeSubscription = this._simulatorViewService
      .getAverageFee(this.sourceFormGroup)
      .subscribe(
        val => {
          this.sourceFormGroup
            .get("feePriceH")
            .setValue(Number(val.h), { emitEvent: false });
          this.sourceFormGroup
            .get("feePriceD")
            .setValue(Number(val.d), { emitEvent: false });
          this.feePriceH = Number(
            this.sourceFormGroup.get("feePriceH").value
          ).toLocaleString("es-AR", {
            style: "currency",
            currency: "ARS",
            minimumFractionDigits: 2
          });
          this.feePriceD = Number(
            this.sourceFormGroup.get("feePriceD").value
          ).toLocaleString("es-AR", {
            style: "currency",
            currency: "ARS",
            minimumFractionDigits: 2
          });
          this.gettingFeePrice = false;
        },
        error => {
          this.gettingFeePrice = false;
        }
      );
  }

  private convertToArgentinian(origin: any): string {
    let value = String(origin).replace(/\./g, "");
    let moneyValue = value.toString();
    let count = 0;
    let maxLengthNumber =
      moneyValue.indexOf(".") !== -1
        ? moneyValue.indexOf(".")
        : moneyValue.length;
    for (let i = maxLengthNumber; i > 0; i--) {
      if (count > 0 && count % 3 === 0) {
        moneyValue = [moneyValue.slice(0, i), ".", moneyValue.slice(i)].join(
          ""
        );
        count = 1;
      } else {
        count++;
      }
    }
    if (moneyValue.charAt(moneyValue.length - 3) === ".") {
      moneyValue =
        moneyValue.substring(0, moneyValue.length - 3) +
        "," +
        moneyValue.substring(moneyValue.length - 3 + 1);
    }

    return moneyValue;
  }

  public setMoneyValue(value: any) {
    this.money = this.convertToArgentinian(value);
    this.sourceFormGroup.get("loanValue").setValue(this.money);

    let comparator = Number(String(this.money).replace(/\./g, ""));
    let max = Number(this.sourceFormGroup.get("creditValues").value[1]);
    let min = Number(this.sourceFormGroup.get("creditValues").value[0]);

    if (comparator > max || comparator < min) {
      this.sourceFormGroup.get("loanValue").setErrors({ incorrect: true });
    }
  }

  public focusLoanValue() {
    this.showPrefix = true;
    if (this.showPrefix) {
      this.placeholderValue = `Monto del préstamo desde ${Number(
        this.sourceFormGroup.get("creditValues").value[0]
      ).toLocaleString("es-AR", {
        style: "currency",
        currency: "ARS",
        minimumFractionDigits: 0
      })} hasta ${Number(
        this.sourceFormGroup.get("creditValues").value[1]
      ).toLocaleString("es-AR", {
        style: "currency",
        currency: "ARS",
        minimumFractionDigits: 0
      })}`;
    }
  }

  public openMenuOption(tabName: string) {
    this._openMenuService.sendMenuActiveTab(tabName);
  }

  public nextStep() {
    this.submitted = true;
    if (this.sourceFormGroup.valid) {
      this._stepperButtons.sendActiveStep(1);
    }
  }

  ngOnDestroy() {
    this.subs.forEach(sub => sub.unsubscribe());
  }
}
