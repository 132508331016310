import { Injectable } from "@angular/core";

@Injectable()
export class GlobalConstants {
  constructor() {}

  //Define endpoint's route
  public readonly API_ENDPOINT: string =
    "https://sbm-pwa-service.herokuapp.com/api/";
  //"http://localhost:3031/api/";

  public readonly apiUrl = {
    //Complete with endpoint's string
    getAverageFee: "CuotaPromedioPWAAgencia",
    getCreditOptions: "initialDC",
    saveCreditRequest: "simularDC",
    askContact: "contactoDC",
    continue: "continuar"
  };
}
