import { Subject, Observable } from "rxjs";
import { Injectable } from "@angular/core";

@Injectable()
export class OpenMenuService {
    private subject = new Subject<any>();
    private backgroundBlurred = new Subject<boolean>();
 
    sendMenuActiveTab(activeView: string) {
        this.subject.next({ activeView: activeView });
    }
 
    clearMenuActiveTab() {
        this.subject.next();
    }
 
    getMenuActiveTab(): Observable<any> {
        return this.subject.asObservable();
    }

    setBlurredBackground(blur: boolean) {
        this.backgroundBlurred.next(blur);
    }
 
    clearBlurredBackground() {
        this.backgroundBlurred.next();
    }
 
    getBlurredBackground(): Observable<any> {
        return this.backgroundBlurred.asObservable();
    }
}
